// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tooltip {
  background-color: #333;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  width: 250px;
}

.custom-tooltip p {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/tooltip.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,SAAS;AACX","sourcesContent":[".custom-tooltip {\n  background-color: #333;\n  color: #fff;\n  padding: 10px;\n  border-radius: 5px;\n  font-size: 14px;\n  width: 250px;\n}\n\n.custom-tooltip p {\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
