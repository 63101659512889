// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal container */
.modal {
    position: fixed; /* Stay in place */
    z-index: 100; /* Sit on top */
    left: 58%;
    top: 150px;
    transform: translate(-50%, 0); /* Center horizontally */
    width: 430px; /* Full width */
    height: 400px; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: #fefefe;
    padding: 20px 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */

  }
  
  /* Modal content */
  .modal-content {
    /* background-color: #fefefe; */
    /* margin: 15% auto;  */
    /* border: 1px solid #888; */
    width: 100%; /* Could be more or less, depending on screen size */

    .header{
        display: flex;
        justify-content: end;
        gap: 15px;
        align-items: center;
height: 30px;

       
    }
    .body-content{

      p{
        font-size: 18px;
    line-height: 30px;
    span{
      font-weight: 700;
    }

      }
    }

  }
  
  
  
  
  `, "",{"version":3,"sources":["webpack://./src/styles/calenderpop.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,eAAe,EAAE,kBAAkB;IACnC,YAAY,EAAE,eAAe;IAC7B,SAAS;IACT,UAAU;IACV,6BAA6B,EAAE,wBAAwB;IACvD,YAAY,EAAE,eAAe;IAC7B,aAAa,EAAE,gBAAgB;IAC/B,cAAc,EAAE,4BAA4B;IAC5C,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,wCAAwC,EAAE,eAAe;;EAE3D;;EAEA,kBAAkB;EAClB;IACE,+BAA+B;IAC/B,uBAAuB;IACvB,4BAA4B;IAC5B,WAAW,EAAE,oDAAoD;;IAEjE;QACI,aAAa;QACb,oBAAoB;QACpB,SAAS;QACT,mBAAmB;AAC3B,YAAY;;;IAGR;IACA;;MAEE;QACE,eAAe;IACnB,iBAAiB;IACjB;MACE,gBAAgB;IAClB;;MAEE;IACF;;EAEF","sourcesContent":["/* Modal container */\n.modal {\n    position: fixed; /* Stay in place */\n    z-index: 100; /* Sit on top */\n    left: 58%;\n    top: 150px;\n    transform: translate(-50%, 0); /* Center horizontally */\n    width: 430px; /* Full width */\n    height: 400px; /* Full height */\n    overflow: auto; /* Enable scroll if needed */\n    background-color: #fefefe;\n    padding: 20px 30px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */\n\n  }\n  \n  /* Modal content */\n  .modal-content {\n    /* background-color: #fefefe; */\n    /* margin: 15% auto;  */\n    /* border: 1px solid #888; */\n    width: 100%; /* Could be more or less, depending on screen size */\n\n    .header{\n        display: flex;\n        justify-content: end;\n        gap: 15px;\n        align-items: center;\nheight: 30px;\n\n       \n    }\n    .body-content{\n\n      p{\n        font-size: 18px;\n    line-height: 30px;\n    span{\n      font-weight: 700;\n    }\n\n      }\n    }\n\n  }\n  \n  \n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
