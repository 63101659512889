export { default as Ecommerce } from './Dashboard';
export { default as Orders } from './Orders';
export { default as LineWorkers } from './LineWorkers';

export { default as Customers } from './Customers';
export { default as AddCustomer} from './Forms/AddCustomer';
export { default as EditCustomer} from './Forms/EditCustomer';

export { default as ProductMaster } from './ProductMaster';
export { default as ProductionIncharge } from './ProductionIncharge';
export { default as LineIncharge } from './LineIncharge';
export { default as AssemblyLine } from './AssemblyLine';
export { default as AssemblyLineStationView } from './AssemblyLineStationView';

export { default as Warehouse} from './Warehouse';
export { default as Calendar } from './Calendar';
export { default as OrderDetails} from './OrderDetails';
export { default as Reports} from './Reports';

export { default as AddOrder} from './Forms/AddOrder';
export { default as EditOrder} from './Forms/EditOrder';

export { default as CreatePlan} from './Forms/CreatePlan'

export { default as AddProduct} from './Forms/AddProduct';
export { default as EditProduct} from './Forms/EditProduct';

export { default as AddPI} from './Forms/AddPI';
export { default as EditProductionIncharge} from './Forms/EditProductionIncharge';


export { default as AddLI} from './Forms/AddLI';
export {default as AddProductionPlan} from './Forms/AddProductionPlan'
export {default as EditProductionPlan} from './Forms/EditProductionPlan'



export { default as EditLI} from './Forms/EditLI';
export { default as AddEmpolyee} from './Forms/AddEmpolyee';

export { default as Kanban } from './Kanban';
export { default as ProductionPlan } from './ProductionPlan';
export { default as RawMaterial } from './RawMaterial';
export { default as AddRawMaterial } from './Forms/AddRawMaterial';
export { default as EditRawMaterial } from './Forms/EditRawMaterial';




export { default as Login} from './Login';


