// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rbc-time-header-content {
  height: 500px;
}

.rbc-time-content {
  display: none;
}
.rbc-time-header-gutter{
    display: none;
}

.rbc-day-bg {
  position: relative;
}

.rbc-row {
  display: flex;
}

.rbc-month-row {
  display: flex;
  flex-direction: column;
}
.rbc-event-content {
  font-size: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/mycalender.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf;AACA;IACI,aAAa;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,eAAe;AACjB","sourcesContent":[".rbc-time-header-content {\n  height: 500px;\n}\n\n.rbc-time-content {\n  display: none;\n}\n.rbc-time-header-gutter{\n    display: none;\n}\n\n.rbc-day-bg {\n  position: relative;\n}\n\n.rbc-row {\n  display: flex;\n}\n\n.rbc-month-row {\n  display: flex;\n  flex-direction: column;\n}\n.rbc-event-content {\n  font-size: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
